@import	"_kit/normalise", "_kit/resets-helpers"; // Standardise elements
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;900&family=Varela+Round&display=swap');

// Our variables
$pink: #e51776;
$blue: #26a2dc;
$lightGrey: #ececec;

$spot: $pink;
$active: $blue;

$base-font-family: 'Nunito Sans', sans-serif;
$base-font-size:	2rem;
$base-font-weight:	400;

$heading-font: 'Varela Round', sans-serif;
$heading-color: $pink;

$base-line-height:	1.8;
$base-text-color:	#727272;
$subtle-text-color: lighten(#727272, 10%);

$divide-background: $lightGrey;

$background-color: white;

$font-break: 820px;


@mixin standard-gradient {
	background-image: linear-gradient(180deg,#52b5e3,#62a0ee,#9981e3,#cc56b9,#e51776);
	background-repeat: no-repeat;
	background-size: auto;
}

@mixin small-caps {
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 900;
	font-family: $base-font-family;
	letter-spacing: 1px;
}

@mixin funky-link {
	color: #333;
	display: inline-block;
	background-image: linear-gradient(180deg,#52b5e3 0,#52b5e3);
	background-position: 0 100%;
	background-repeat: repeat-x;
	background-size: 3px 3px;
	transition: background-size 0.2s;

	&:hover {
		color: #000;
		background-size: 6px 6px;
	}
}

html,
body {
	padding: 0;
	margin: 0;
	position: relative;
  	height: 100%;
}
 
html {
	font-size: 62.5%;
}

body {
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	background: $background-color;
	color: $base-text-color;
	line-height: $base-line-height;
	text-rendering: optimizeLegibility;
  	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.6rem;
	min-height: calc(100vh - 7rem);
	padding: 0 0.8rem;

	@media only screen and (min-width:670px) {
		padding: 0 1.6rem;
	}
	

	@media only screen and (min-width:$font-break) {
		font-size: $base-font-size;
	}

	&::before, &::after {
		content: '';
		display: block;
		position: fixed;
		top: 0;
		bottom: 0;
		width: 0.8rem;
		@include standard-gradient;
		z-index: 10;

		@media only screen and (min-width:670px) {
			width: 1.6rem;
		}
	}

	&::before {
		right: 0;
	}

	&::after {
		left: 0;
	}

	&[data-theme="dark"]{
		background: #000418;
		color: #f8f8f8;

		&::before, &::after {
			opacity: 0.3;
		}

		header, .post-item, .article .post-meta, .article figure {
			border-color: rgba(255,255,255,0.1);
		}

		header img {

			&.logo-light {
				display: none;
			}

			&.logo-dark {
				display: block;
			}

		}

		.theme-switcher {

			.theme-light {
				display: block;
			}

			.theme-dark {
				display: none;
			}

		}

		.article hr, .post-follow, .article p code, .article figure.portrait span {
			background: rgba(255,255,255,0.1);
		}

		.article p code {
			border-color: rgba(255,255,255,0.2);
			color: white;
		}

		.article a, .intro a {
			color: #f8f8f8;

			&:hover {
				color: white;
			}
		}

		.post-follow a {
			color: lighten($active, 10%);
		}

	}

}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4 {
	color: $heading-color;
	font-weight: normal;
	line-height: 1em;
	padding-bottom: 0.35em;
	font-family: $heading-font;
	letter-spacing: -1.5px;

	& + p {
		margin-top: 0;
	}

}

.button {
	position: relative;
	display: inline-block;
	background: $active;
	font-size: 1rem;
	text-align: center;
	text-transform: uppercase;
	font-weight: 900;
	color: white !important;
	letter-spacing: 1px;
	border-radius: 6px;
	border: 0;
	min-width: 100%;
	z-index: 10;
	transition: all .3s;
	opacity: 1;
	cursor: pointer;
	height: 4.4rem;
	line-height: 4.4rem;

	&:hover {
		background: darken($active, 10%);
	}

	&--cta {
		width: 20rem;
		min-width: 20rem;
		margin-top: 1rem;
		margin-bottom: 0.8rem;
	}
}

header {
	position: relative;
	border-bottom: 1px solid $divide-background;
	padding: 2.4rem 0;
	text-align: center;

	a {
		display: block;
		margin: 0 auto;
		width: 21rem;
	}

	img {
		display: block;
		margin: -0.5rem auto 0 auto;
		padding-left: 3rem;
		width: 21rem;

		&.logo-dark {
			display: none;
		}

		@media only screen and (min-width:$font-break) {
			margin-top: -1rem;
			padding-left: 3.5rem;
		}
	}

	.theme-switcher {
		position: absolute;
		top: 50%;
		left: 2.4rem;
		margin-top: -2.4rem;
		width: 4.8rem;
		height: 4.8rem;
		color: $active;
		cursor: pointer;
		display: flex;
		z-index: 10;

		svg {
			position: relative;
			width: 24px;
			height: 24px;
			margin: auto;

			&.theme-dark {
				
				path {
					fill:#000418;
				}
			}

			&.theme-light {
				display: none;
				path {
					fill:#ffffff;
				}
			}

		}
	}
}

.global-main {
	padding-top: 2.4rem;

	@media only screen and (min-width:670px) {
		padding-top: 6.4rem;
	}
}

#global-wrapper {
	max-width: 92.8rem;
	margin: 0 auto;
	padding: 0 1.6rem;

	@media only screen and (min-width:670px) {
		padding: 0 5.6rem;
	}
}

.intro {
	a {
		@include funky-link;
	}
}

.post-meta {
	@include small-caps;
	color: $subtle-text-color;

	.post-date-time {
		margin-right: 1.6rem;
		display: inline-block;
	}

	.post-length {
		display: inline-block;
	}

	.post-prefix {
		display: none;

		@media only screen and (min-width:$font-break) {
			display: inline-block;
		}
	}

	svg {
		width: 18px;
		height: 18px;
		display: inline-block;
		vertical-align: middle;
		margin-top: -0.3rem;
	}
}

.post-item {
	font-size: 1.6rem;
	position: relative;
	border-top: 1px solid $divide-background;
	border-bottom: 1px solid $divide-background;
	padding: 2.4rem 0;

	@media only screen and (min-width:$font-break) {
		padding: 2.4rem 1.6rem;
	}

	.post-meta {
		padding-bottom: 0.8rem;
	}

	a {
		color: darken($active, 10%);
	}

	p {
		margin: 0;
	}
}

.article {

	.post-meta {
		padding-bottom: 2.4rem;
		border-bottom: 1px solid $divide-background;
	}

	h2, h3, h4 {
		margin-top: 1.5em;
	}

	a {
		@include funky-link;
	}

	ul, ol {
		margin-left: 3.6rem;
	}

	ul {
		list-style-type: disc;
	}

	p code {
		display: inline-block;
		background: $divide-background;
		color: darken($divide-background, 50%);
		border: 1px solid darken($divide-background, 10%);
		border-radius: 3px;
		padding: 0.1rem 0.4rem;
		font-size: 1.4rem;

		@media only screen and (min-width:$font-break) {
			padding: 0.6rem;
			font-size: 1.6rem;
		}
	}

	hr {
		border: 0;
		height: 1px;
		background: $divide-background;
	}

}

figure {
	margin: 0;
	padding: 1.6rem 0;
	border: 1px solid $divide-background;
	border-left: 0;
	border-right: 0;

	&.portrait {

		span {
			display: block;
			width: 100%;
			background: $divide-background;
		}

		img {
			max-width: 40rem;
		}

	}

	img {
		display: block;
		width: 100%;
		margin: 0 auto;
	}

	figcaption {
		font-size: 1.5rem;
		line-height: 1.3em;
		padding-top: 1.6rem;
		color: $subtle-text-color;
		font-style: italic;
	}

	&.quotation {
		display: block;
		border: 0;
		border-left: 0.5rem solid $pink !important;

		blockquote {
			margin: 0 0 0 2.4rem;
			font-weight: bold;

			p:first-of-type {
				margin-top: 0;
			}

			p:last-of-type {
				margin-bottom: 0;
			}
		}

		figcaption {
			padding-top: 1rem;
			margin: 0 2.4rem;
		}
		
	}
}



.social-sharing {
	width: 15rem;
	display: flex;
	
	a {
		width: 4.2rem;
		height: 4.2rem;
		border-radius: 50%;
		color: $active;
		display: flex;
		background: white;
		margin: 0 0.4rem;
		// border: 2px solid $active;
		transition: all, 0.3s;

		svg {
			margin: auto;
		}

		&:hover {
			background: $active;
			color: white;
		}

	}
}

.post-follow {
	background: $divide-background;
	padding: 2.4rem 1.6rem;
	margin-bottom: 1rem;
	margin-top: 8rem;
	border-radius: 6px;

	@media only screen and (min-width:580px) {
		margin-top: 3.2rem;
		padding: 2.4rem;
		display: flex;
	}

	.post-share {
		padding-top: 2.4rem;
		padding-bottom: 1.6rem;
		text-align: center;

		@media only screen and (min-width:580px) {
			padding-top: 0.4rem;
			padding-bottom: 0;
			width: 50rem;
		}

		.social-sharing {
			width: 100%;
			justify-content: center;
			margin-top: 0.6rem;
		}
	}

	.post-biog {
		position: relative;
		font-size: 1.6rem;
		padding-top: 3.2rem;
		text-align: center;

		a {
			color: darken($active, 15%);
		}

		@media only screen and (min-width:580px) {
			padding-top: 0;
			padding-left: 15%;
			padding-right: 1.6rem;
			text-align: left;
		}

		img {
			position: absolute;
			display: block;
			border-radius: 50%;
			top: -8rem;
			left: 50%;
			margin-left: -5rem;
			width: 10rem;

			@media only screen and (min-width:580px) {
				top: 0;
				left: 0;
				margin-left: 0;
				margin-right: 1.6rem;
				width: 17%;
			}
		}
	}


}

.post-share {
	@include small-caps;
}

.global-footer {
	@include small-caps;
	padding: 1.6rem 0;
	text-align: center;

	a {
		color: $active;
	}
}

.highlight {
	padding: 1.6rem;
	overflow-x: auto;
	background: #272822;
	border-radius: 6px;
	border: 0;
	font-size: 1.4rem;

	@media only screen and (min-width:$font-break) {
		font-size: 1.6rem;
	}

	code {
		background: transparent;
	}

	&.minimize {
		height: 8.1rem;
		overflow: hidden;
		position: relative;
		background: transparentize(#272822, 0.4);
		transition: all, 0.3s;

		pre {
			filter: blur(2px);
			background: transparent;
		}
		
		&:before {
			content:'';
			display: block;
			position: absolute;
			z-index: 10;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: transparentize(#272822, 0.5);
			transition: all, 0.3s;
		}

		&:after {
			content:'Show Code';
			color: white;
			position: absolute;
			z-index: 15;
			top: 50%;
			left: 50%;
			margin-left: -10.2rem;
			margin-top: -2.2rem;
			border: 2px solid white;
			width: 20rem;
			height: 4rem;
			border-radius: 6px;
			line-height: 4rem;
			text-align: center;
			@include small-caps;
			cursor: pointer;
			transition: all, 0.3s;
		}

		&:hover {
			background: transparentize(#272822, 0);
			cursor: pointer;

			&:before {
				background: transparentize(#272822, 0.8);
			}
			&:after {
				background: $active;
				border-color: $active;
			}

		}
	}
}

// Code Highlighting
// https://github.com/jwarby/jekyll-pygments-themes
// http://jwarby.github.io/jekyll-pygments-themes/languages/javascript.html
.highlight pre { background-color: #272822; }
.highlight .hll { background-color: #272822; }
.highlight .c { color: #75715e } /* Comment */
.highlight .err { color: #960050; background-color: #1e0010 } /* Error */
.highlight .k { color: #66d9ef } /* Keyword */
.highlight .l { color: #ae81ff } /* Literal */
.highlight .n { color: #f8f8f2 } /* Name */
.highlight .o { color: #f92672 } /* Operator */
.highlight .p { color: #f8f8f2 } /* Punctuation */
.highlight .cm { color: #75715e } /* Comment.Multiline */
.highlight .cp { color: #75715e } /* Comment.Preproc */
.highlight .c1 { color: #75715e } /* Comment.Single */
.highlight .cs { color: #75715e } /* Comment.Special */
.highlight .ge { font-style: italic } /* Generic.Emph */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .kc { color: #66d9ef } /* Keyword.Constant */
.highlight .kd { color: #66d9ef } /* Keyword.Declaration */
.highlight .kn { color: #f92672 } /* Keyword.Namespace */
.highlight .kp { color: #66d9ef } /* Keyword.Pseudo */
.highlight .kr { color: #66d9ef } /* Keyword.Reserved */
.highlight .kt { color: #66d9ef } /* Keyword.Type */
.highlight .ld { color: #e6db74 } /* Literal.Date */
.highlight .m { color: #ae81ff } /* Literal.Number */
.highlight .s { color: #e6db74 } /* Literal.String */
.highlight .na { color: #a6e22e } /* Name.Attribute */
.highlight .nb { color: #f8f8f2 } /* Name.Builtin */
.highlight .nc { color: #a6e22e } /* Name.Class */
.highlight .no { color: #66d9ef } /* Name.Constant */
.highlight .nd { color: #a6e22e } /* Name.Decorator */
.highlight .ni { color: #f8f8f2 } /* Name.Entity */
.highlight .ne { color: #a6e22e } /* Name.Exception */
.highlight .nf { color: #a6e22e } /* Name.Function */
.highlight .nl { color: #f8f8f2 } /* Name.Label */
.highlight .nn { color: #f8f8f2 } /* Name.Namespace */
.highlight .nx { color: #a6e22e } /* Name.Other */
.highlight .py { color: #f8f8f2 } /* Name.Property */
.highlight .nt { color: #f92672 } /* Name.Tag */
.highlight .nv { color: #f8f8f2 } /* Name.Variable */
.highlight .ow { color: #f92672 } /* Operator.Word */
.highlight .w { color: #f8f8f2 } /* Text.Whitespace */
.highlight .mf { color: #ae81ff } /* Literal.Number.Float */
.highlight .mh { color: #ae81ff } /* Literal.Number.Hex */
.highlight .mi { color: #ae81ff } /* Literal.Number.Integer */
.highlight .mo { color: #ae81ff } /* Literal.Number.Oct */
.highlight .sb { color: #e6db74 } /* Literal.String.Backtick */
.highlight .sc { color: #e6db74 } /* Literal.String.Char */
.highlight .sd { color: #e6db74 } /* Literal.String.Doc */
.highlight .s2 { color: #e6db74 } /* Literal.String.Double */
.highlight .se { color: #ae81ff } /* Literal.String.Escape */
.highlight .sh { color: #e6db74 } /* Literal.String.Heredoc */
.highlight .si { color: #e6db74 } /* Literal.String.Interpol */
.highlight .sx { color: #e6db74 } /* Literal.String.Other */
.highlight .sr { color: #e6db74 } /* Literal.String.Regex */
.highlight .s1 { color: #e6db74 } /* Literal.String.Single */
.highlight .ss { color: #e6db74 } /* Literal.String.Symbol */
.highlight .bp { color: #f8f8f2 } /* Name.Builtin.Pseudo */
.highlight .vc { color: #f8f8f2 } /* Name.Variable.Class */
.highlight .vg { color: #f8f8f2 } /* Name.Variable.Global */
.highlight .vi { color: #f8f8f2 } /* Name.Variable.Instance */
.highlight .il { color: #ae81ff } /* Literal.Number.Integer.Long */

.highlight .gh { } /* Generic Heading & Diff Header */
.highlight .gu { color: #75715e; } /* Generic.Subheading & Diff Unified/Comment? */
.highlight .gd { color: #f92672; } /* Generic.Deleted & Diff Deleted */
.highlight .gi { color: #a6e22e; } /* Generic.Inserted & Diff Inserted */